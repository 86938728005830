import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import {
  fetchBankInfosApi,
  fetchBusinessInfosApi,
  fetchDistrictsApi,
  fetchKrBanksApi,
  fetchProvincesApi
} from '@/apis/business-bank-info.api';
import { BUSINESS_BANK_STATUS } from '@/constants/business-bank.const';
import { COUNTRY_OPTIONS } from '@/constants/national-list.const';
import { BusinessReviewStatus } from '@/enums/business.enum';
import { Confirmation } from '@/enums/common.enum';
import {
  fetchBankApprovalMapper,
  fetchBankInfoMapper,
  fetchKrBanksMapper
} from '@/mappers/bank.mapper';
import {
  businessInfoMapper,
  fetchBusinessApprovalMapper,
  fetchBusinessRegistrationMapper
} from '@/mappers/business.mapper';
import { useUserStore } from '@/stores/user.store';
import type { BankApproveModel, BankInfoModel } from '@/types/bank-info/bank-model.type';
import type {
  BusinessApprovalModel,
  BusinessInfoModel,
  CompanyInfoModel
} from '@/types/business-info/business-model.type';
import type { District, Province } from '@/types/business-info/business-response.type';
import type { FormOption, FormOptionGroup } from '@/types/common/form.type';

export const useBusinessBankStore = defineStore('businessBankStore', () => {
  // Business Information
  const businessStatus = ref<string>('');
  const businessInfos = ref<BusinessInfoModel>();
  const businessApproval = ref<BusinessApprovalModel>();
  const businessInfo = ref<CompanyInfoModel>();
  const companyLicense = ref<string>();
  const bizCheckedYn = ref<string>(Confirmation.NO);

  // Bank Information
  const bankStatus = ref<string>('');
  const bankInfo = ref<BankInfoModel>();
  const bankApproval = ref<BankApproveModel>();
  const krBanks = ref<FormOption[]>();
  const bankCheckedYn = ref<string>(Confirmation.NO);

  // provinces - districts
  const provinces = ref<Province[]>([]);
  const districts = ref<District[]>([]);

  const cities = ref<Province[]>([]);
  const wards = ref<District[]>([]);

  const defaultCountryOption = ref<FormOptionGroup<string> | undefined>();

  // Computed status
  const isBusinessCompleted = computed(() => businessStatus.value === BUSINESS_BANK_STATUS.APPROVE);
  const isBankCompleted = computed(() => bankStatus.value === BUSINESS_BANK_STATUS.APPROVE);
  const setDefaultCountryOption = (value: string) => {
    const option = COUNTRY_OPTIONS.find((item: FormOption) => item.value === value);

    defaultCountryOption.value = option;
  };

  const userStore = useUserStore();

  const { selectedGroupId } = storeToRefs(userStore);

  const fetchBusinessInfos = async () => {
    if (selectedGroupId.value) {
      const res = await fetchBusinessInfosApi(selectedGroupId.value);
      if (res) {
        let approvalCurrentInfo;
        if (res.approveCompanyInfo) {
          approvalCurrentInfo = res.approveCompanyInfo;
        } else {
          approvalCurrentInfo = res.latestCompanyInfo;
        }
        businessApproval.value = fetchBusinessApprovalMapper(approvalCurrentInfo, res.reviewInfo);
        businessStatus.value = res.reviewInfo?.status ?? '';

        let currentInfo;
        if (businessStatus.value === BusinessReviewStatus.Request || !res.approveCompanyInfo) {
          currentInfo = res.latestCompanyInfo;
        } else {
          currentInfo = res.approveCompanyInfo;
        }

        businessInfos.value = fetchBusinessRegistrationMapper(currentInfo);
        businessInfo.value = businessInfoMapper(
          currentInfo.companyInfo.businessInfo,
          currentInfo.companyInfo,
          currentInfo.contracts
        );
        companyLicense.value = businessInfos.value.individual.license;
        bizCheckedYn.value = businessApproval.value.checkedYn || Confirmation.NO;
      }
    }
  };

  const resetData = () => {
    businessApproval.value = undefined;
    businessInfos.value = undefined;
    businessStatus.value = '';
    businessInfo.value = undefined;
    companyLicense.value = undefined;
    bizCheckedYn.value = Confirmation.NO;

    bankApproval.value = undefined;
    bankInfo.value = undefined;
    bankStatus.value = '';
    bankCheckedYn.value = Confirmation.NO;
  };

  const fetchBankInfos = async () => {
    if (selectedGroupId.value) {
      const res = await fetchBankInfosApi(selectedGroupId.value);
      if (res) {
        bankApproval.value = fetchBankApprovalMapper(res);
        bankInfo.value = fetchBankInfoMapper(res);
        bankStatus.value = fetchBankApprovalMapper(res).status;
        bankCheckedYn.value = fetchBankApprovalMapper(res).checkedYn || Confirmation.NO;
      }
    }
  };

  const fetchKrBanks = async () => {
    const res = await fetchKrBanksApi();
    if (res) {
      krBanks.value = fetchKrBanksMapper(res);
    }
  };

  const fetchProvinces = async () => {
    const res = await fetchProvincesApi();
    if (res) {
      // <!-- 3.1-2-4-A | case VN: provinces - districts -->
      provinces.value = res.vn;
      // <!-- 3.1-2-4-A | case TW: cities - wards -->
      cities.value = res.tw;
    }
  };

  const fetchDistricts = async () => {
    const res = await fetchDistrictsApi();
    if (res) {
      // <!-- 3.1-2-4-A | case VN: provinces - districts -->
      districts.value = res.vn;
      // <!-- 3.1-2-4-A | case TW: cities - wards -->
      wards.value = res.tw;
    }
  };

  return {
    fetchBusinessInfos,
    businessStatus,
    businessInfos,
    businessInfo,
    businessApproval,
    companyLicense,
    bizCheckedYn,
    fetchBankInfos,
    fetchKrBanks,
    bankStatus,
    bankInfo,
    bankApproval,
    krBanks,
    bankCheckedYn,
    fetchProvinces,
    provinces,
    cities,
    fetchDistricts,
    districts,
    wards,
    isBusinessCompleted,
    isBankCompleted,
    defaultCountryOption,
    setDefaultCountryOption,
    resetData
  };
});
